import { useClient } from '@packfleet/feature-flags';
import posthog from 'posthog-js';
import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import {
  UserRole,
  useCurrentUserQuery,
  useIntercomIdentityVerificationTokenQuery,
} from '../generated/graphql';

let userIdentified = false;

export function useCurrentUser() {
  const { data } = useCurrentUserQuery();

  const user = data?.currentUser?.user;
  const userOrImpersonator = data?.currentUser?.impersonator || user;

  const { data: intercomData } = useIntercomIdentityVerificationTokenQuery({
    skip: !user,
  });
  const token = intercomData?.intercomIdentityVerificationToken.token;

  const intercom = useIntercom();
  const featureFlags = useClient();

  useEffect(() => {
    if (!user || !token || !user.email) {
      return;
    }

    intercom.update({
      email: user.email,
      name: user.name,
      company: user.organization
        ? { companyId: user.organization.id }
        : undefined,
      userId: user.id,
      userHash: token,
    });
  }, [user, token, intercom]);

  // Identify the user
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook specifies more dependencies than necessary: userIdentified
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies: user.id, user?.email, user?.organization, user.organization.id, user?.name
  useEffect(() => {
    if (!userOrImpersonator || !userOrImpersonator.email || !user?.email) {
      return;
    }

    if (!userIdentified && featureFlags) {
      // For some services we only want to identify the user once per session
      void featureFlags
        .identify({
          key: userOrImpersonator.id,
          email: userOrImpersonator.email,
          anonymous: false,
          custom: {
            organizationId: userOrImpersonator.organization?.id ?? '',
          },
        })
        .then((flags) => {
          const sessionRecordingEnabled =
            flags['merchant-session-recording-enabled'];

          posthog.identify(userOrImpersonator.id, {
            email: userOrImpersonator.email,
          });

          const isDevelopment = process.env.NODE_ENV === 'development';
          if (!isDevelopment && sessionRecordingEnabled) {
            // Only use session recordings for external users
            posthog.startSessionRecording({});
          }
        });

      userIdentified = true;
    }
  }, [userOrImpersonator, featureFlags, userIdentified]);

  return user;
}

export function useIsBrandUser() {
  const user = useCurrentUser();
  return {
    hasUserLoaded: user != null,
    isBrandUser: (user?.brandIds?.length ?? 0) > 0,
  };
}

export function useUserRole() {
  const user = useCurrentUser();
  return {
    hasUserLoaded: user != null,
    hasOwnerRole: user?.role === UserRole.Owner,
    hasWriteRole:
      user?.role === UserRole.Owner || user?.role === UserRole.Write,
  };
}

export function useIsPricingVisible() {
  const user = useCurrentUser();
  return {
    hasUserLoaded: user != null,
    isPricingVisible: user?.hasOrganizationPricingAccess,
  };
}
