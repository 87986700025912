import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from '@/components/ui/sidebar';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { useCurrentUser, useUserRole } from '@/hooks/useCurrentUser';
import { useLocalFlag } from '@/hooks/useLocalFlag';
import { cn } from '@/lib/utils';
import { EventName, useAnalytics } from '@packfleet/analytics';
import cs from 'classnames';
import { differenceInDays } from 'date-fns';
import {
  Boxes,
  ChartNoAxesCombined,
  CircleUser,
  HandHeart,
  House,
  Map,
  MapPinCheckInside,
  PackagePlus,
} from 'lucide-react';
import { NextParsedUrlQuery } from 'next/dist/server/request-meta';
import { useRouter } from 'next/router';
import React, { ReactElement, ReactNode } from 'react';
import { Routes, route } from '../../utilities/routes';
import { useCountImportableShipments } from '../inbox/useCountImportableShipments';
import Link from '../link/Link';

const ImportableShipmentsBadge = () => {
  const { totalImportableShipments, importableShipmentsIsPaginated } =
    useCountImportableShipments();

  if (!totalImportableShipments) {
    return null;
  }

  return (
    <span className="animation-scaledFadeIn absolute -top-1.5 -right-2 z-10 flex h-3.5 items-center justify-center rounded-full bg-attention-foreground px-1 text-[9px] font-medium text-background">
      <span className="inline-block">
        {totalImportableShipments}
        {importableShipmentsIsPaginated ? '+' : undefined}
      </span>
    </span>
  );
};

const referVersion = '14-01-2022';
const referKey = 'packfleet-refer';

const WithReferralBadge = ({ children }: { children?: ReactNode }) => {
  const [seenRefer, setSeenRefer] = useLocalFlag(referKey, referVersion);
  const analytics = useAnalytics();
  return (
    <div
      className="relative [&>svg]:size-4"
      onClick={() => {
        analytics?.track(EventName.clickedMerchantReferral25OffSidebarButton);
        setSeenRefer();
      }}
    >
      {!seenRefer ? (
        <div className="animation-scaledFadeIn absolute -top-1.5 -right-2 z-10 flex h-2 items-center justify-center rounded-full bg-attention-foreground px-1 text-xxs font-medium text-primaryInverted" />
      ) : null}
      {children}
    </div>
  );
};

type NavItem = {
  href: string;
  title: string;
  icon: ReactElement;
  alignment: 'start' | 'end';
  className?: string;
};

const getNavigationItems = (
  query: NextParsedUrlQuery,
  showReferLink: boolean,
  hasWriteRole: boolean,
) =>
  [
    {
      href: route(Routes.home),
      title: 'Dashboard',
      icon: <House />,
      alignment: 'start',
    },
    hasWriteRole
      ? {
          href: route(Routes.appShipments),
          title: 'Add shipments',
          icon: (
            <div className="relative [&>svg]:size-4">
              <ImportableShipmentsBadge />
              <PackagePlus />
            </div>
          ),
          alignment: 'start',
        }
      : undefined,
    {
      href: route(Routes.appShipmentsToCollect, {
        locationId: query.locationId,
        slug: query.slug,
      }),
      title: 'To collect',
      icon: <Boxes />,
      alignment: 'start',
    },
    {
      href: route(Routes.appShipmentsOnTheirWay, {
        locationId: query.locationId,
        slug: query.slug,
      }),
      title: 'On their way',
      icon: <Map />,
      alignment: 'start',
    },
    {
      href: route(Routes.appShipmentsDelivered, {
        locationId: query.locationId,
        slug: query.slug,
      }),
      title: 'Delivered',
      icon: <MapPinCheckInside />,
      alignment: 'start',
    },
    // Bottom,
    {
      href: route(Routes.appAnalytics),
      title: 'Analytics',
      icon: <ChartNoAxesCombined />,
      alignment: 'end',
    },
    showReferLink
      ? {
          href: 'https://blog.packfleet.com/refer',
          title: 'Refer to get 25% off',
          icon: (
            <WithReferralBadge>
              <HandHeart />
            </WithReferralBadge>
          ),
          alignment: 'end',
        }
      : undefined,
    {
      href: route(Routes.appSettings),
      title: 'Settings',
      icon: <CircleUser />,
      alignment: 'end',
    },
  ].filter((item): item is NavItem => !!item);

const NavLink = ({
  children,
  href,
  className,
}: {
  children?: ReactNode;
  href: string;
  className?: string;
}) => {
  const router = useRouter();
  const isActive = router.pathname === href.split('?')[0];

  return (
    <Link
      className={cs(className, {
        'bg-attention': isActive,
      })}
      href={href}
      unstyled
    >
      {children}
    </Link>
  );
};

export const NavBar = ({
  className,
  style,
}: { className?: string; style?: React.CSSProperties }) => {
  const router = useRouter();
  const user = useCurrentUser();
  const { hasWriteRole } = useUserRole();

  // Only show the referral link to accounts younger than 30 days
  const accountCreatedDaysAgo = user?.organization?.createdAt
    ? differenceInDays(new Date(), new Date(user?.organization?.createdAt))
    : 0;
  const showReferLink = accountCreatedDaysAgo > 30;

  const navItems = getNavigationItems(
    router.query,
    showReferLink,
    hasWriteRole,
  );

  const { open } = useSidebar();

  return (
    <Sidebar
      collapsible="icon"
      variant="inset"
      className={cn('z-20', className)}
      style={style}
    >
      <SidebarContent>
        <SidebarGroup>
          <SidebarGroupContent>
            <SidebarMenu>
              <SidebarMenuItem>
                <Link href={route(Routes.home)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="ml-1 mb-4 mt-3.5"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M24 0H0.00677597V3.17181H20.7869V20.8282H3.22009V15.6536H6.60425C7.52037 15.6536 8.29975 15.5254 8.9424 15.2689C9.58505 14.999 10.1046 14.648 10.5012 14.2161C10.9114 13.7842 11.2054 13.2915 11.3831 12.7381C11.5745 12.1712 11.6702 11.5975 11.6702 11.0171C11.6702 10.4232 11.5745 9.84956 11.3831 9.29615C11.2054 8.74274 10.9114 8.25007 10.5012 7.81814C10.1046 7.38621 9.58505 7.04202 8.9424 6.78556C8.29975 6.5156 7.52037 6.38063 6.60425 6.38063H0V20.8368H0.00570247V24H24V0ZM3.22009 8.85072V13.1835H5.72232C6.0915 13.1835 6.44701 13.1565 6.78884 13.1025C7.13068 13.0485 7.43149 12.9473 7.69129 12.7988C7.95108 12.6369 8.15618 12.4141 8.30659 12.1307C8.47067 11.8472 8.55271 11.476 8.55271 11.0171C8.55271 10.5582 8.47067 10.187 8.30659 9.90355C8.15618 9.6201 7.95108 9.40413 7.69129 9.25566C7.43149 9.09368 7.13068 8.9857 6.78884 8.93171C6.44701 8.87772 6.0915 8.85072 5.72232 8.85072H3.22009Z"
                      fill="#037362"
                    />
                  </svg>
                </Link>
              </SidebarMenuItem>
              {navItems
                .filter(({ alignment }) => alignment !== 'end')
                .map(({ href, title, icon, className }) => (
                  <Tooltip key={title}>
                    <TooltipTrigger asChild>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <NavLink
                            key={title}
                            href={href}
                            className={className}
                          >
                            {icon}
                            <span className="relative pl-0.5">{title}</span>
                          </NavLink>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                    </TooltipTrigger>

                    <TooltipContent
                      className={open ? 'hidden' : ''}
                      side="right"
                    >
                      {title}
                    </TooltipContent>
                  </Tooltip>
                ))}
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
      <SidebarFooter>
        <SidebarMenu>
          {navItems
            .filter(({ alignment }) => alignment === 'end')
            .map(({ href, title, icon, className }) => (
              <Tooltip key={title}>
                <TooltipTrigger asChild>
                  <SidebarMenuItem>
                    <SidebarMenuButton asChild>
                      <NavLink key={title} href={href} className={className}>
                        {icon}
                        <span className="relative pl-0.5">{title}</span>
                      </NavLink>
                    </SidebarMenuButton>
                  </SidebarMenuItem>
                </TooltipTrigger>

                <TooltipContent className={open ? 'hidden' : ''} side="right">
                  {title}
                </TooltipContent>
              </Tooltip>
            ))}
        </SidebarMenu>
      </SidebarFooter>
    </Sidebar>
  );
};
