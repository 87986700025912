import { useCountThirdPartyAppImportableShipmentsQuery } from '@/generated/graphql';
import { useIsBrandUser } from '@/hooks/useCurrentUser';

export const useCountImportableShipments = () => {
  const { hasUserLoaded, isBrandUser } = useIsBrandUser();

  const variables = {
    input: {
      excludeErrors: true,
      excludeWarnings: true,
    },
  };

  const { data } = useCountThirdPartyAppImportableShipmentsQuery({
    variables,
    // Brand users are not able to view importable shipments as they are not associated with
    // any brand (at least for now)
    skip: !hasUserLoaded || isBrandUser,
  });

  const totalImportableShipments =
    data?.countThirdPartyAppImportableShipments?.count;
  const importableShipmentsIsPaginated =
    data?.countThirdPartyAppImportableShipments?.hasMoreData;

  return {
    totalImportableShipments,
    importableShipmentsIsPaginated,
  };
};
